<template>
  <section class="module-charts-pie p-4 mb-5">
    <v-chart class="chart" :option="option" />
  </section>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);


export default {
  name: 'ChartPie',
  components: {
    VChart
  },

  provide: {
    [THEME_KEY]: "light"
  },

  data() {
    return {
      option: {

        title: {
          text: "Servicios Ofrecidos por Consulado",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{a}</b> <br> {b} : {c}", // Bar
        },
        legend: {
          orient: "vertical",
          left: "right",
          //dimensions: true,
          dimensions: ["Número total de personas atendidas"],
        },

        xAxis: { show: false, type: "category", name: "NMBRE", nameLocation: "middle" },
        yAxis: { type: "value" },

        series: [

          {
            name: "Consulmex Indianapolis",
            type: "bar",
            data: [
              {value: 630, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },

          {
            name: "Consulmex Raleigh",
            type: "bar",
            data: [
              {value: 556, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },

          {
            name: "Consulmex Calexico",
            type: "bar",
            data: [
              {value: 1, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },

          {
            name: "Consulmex Fresno",
            type: "bar",
            data: [
              {value: 1142, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },

          {
            name: "Consulmex Los Angeles",
            type: "bar",
            data: [
              {value: 136, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },

          {
            name: "Consulmex Leamington",
            type: "bar",
            data: [
              {value: 35, name: "Número total de personas atendidas"},
              //{value: 0, name: "ITEM"},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },


        ],
        textStyle: {
          fontFamily: 'Montserrat, "Helvetica Neue", Arial, sans-serif'
        },
      }
    };
  }


}

</script>
