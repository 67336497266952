<template>
  <section class="module-charts-pie p-5">
    <v-chart class="chart" :option="option" />
  </section>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);


export default {
  name: 'ChartPie',
  components: {
    VChart
  },

  provide: {
    [THEME_KEY]: "light"
  },

  data() {
    return {
      option: {
        title: {
          text: "Evento",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{a}</b> <br/>{b} : <b>{c}</b> ({d}%)", //Pie
        },
        legend: {
          orient: "vertical",
          left: "right",
          data: [

            "Inauguración SBS 2021",
            "Feria de Salud (Consulado Móvil)",
            "Actualizaciones sobre las vacunas contra el COVID-19",
            "Beneficios en salud fisica mental y comunitaria del Zumba",
            "El COVID-19 y sus secuelas",
            "Eliminar los mitos sobre el COVID-19",
            "Feria de la salud ",
            "Jornada de vacunación de COVID-19",
            "Taller prevención de enfermedades",
            "Donación de Órganos",
            "Taller Salud Mental",

          ]
        },
        series: [
          {
            name: "Evento",
            type: "pie",
            radius: "75%",
            center: ["50%", "60%"],
            data: [
              
              {value: 1759, name: "Inauguración SBS 2021"},              
              {value: 685,  name: "Feria de Salud (Consulado Móvil)"},              
              {value: 209,  name: "Actualizaciones sobre las vacunas contra el COVID-19"},              
              {value: 106,  name: "Beneficios en salud fisica mental y comunitaria del Zumba"},              
              {value: 104,  name: "El COVID-19 y sus secuelas"},              
              {value: 393,  name: "Eliminar los mitos sobre el COVID-19"},              
              {value: 827,  name: "Feria de la salud "},              
              {value: 581,  name: "Jornada de vacunación de COVID-19"},              
              {value: 98,   name: "Taller prevención de enfermedades"},              
              {value: 53,   name: "Donación de Órganos"},              
              {value: 62,   name: "Taller Salud Mental"},

            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }

          }
        ],
        textStyle: {
          fontFamily: 'Montserrat, "Helvetica Neue", Arial, sans-serif'
        },
      }
    };
  }


}

</script>
