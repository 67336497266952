<template>
  <section class="module-charts">
    <b-container class="mt-5 pt-4">
      <b-row>
        <b-col cols="12">
          <h1>XXI Semana Binacional de Salud</h1>
          <hr class="line">
          <ChartPie></ChartPie>
          <hr class="mb-5 mt-5">
          <ChartBar></ChartBar>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ChartPie from '@/components/Custom/Charts/ChartPie.vue';
import ChartBar from '@/components/Custom/Charts/ChartBar.vue';
//import ApexCharts from 'apexcharts';

export default {
  name: 'Charts',
  components: {
    ChartPie,
    ChartBar,
  }

}
</script>